@tailwind base;
@tailwind components;
@tailwind utilities;

/* Put our main styles here
========================================================================== */
html {
  font-size: 14px;
  line-height: 1.4em;
  min-height: 100vh;
  display: flex;
}

body {
  font-family: sans-serif;
}

body,
#root {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: hidden;
}

body.is-noscroll {
  overflow: hidden;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #939495;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #939495;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #939495;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #939495;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #939495;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #939495;
}

::-webkit-scrollbar {
  /* width of the entire scrollbar */
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  /* tracking area */
  border-radius: 6px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #a0a4a8; /* color of the scroll thumb */
  border-radius: 6px;
  border: 3px solid #f8f8f8; /* creates padding around scroll thumb */
}
