@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('./PlusJakartaSans-Regular.woff2') format('woff2'),
    url('./PlusJakartaSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('./PlusJakartaSans-Bold.woff2') format('woff2'), url('./PlusJakartaSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('./PlusJakartaSans-Medium.woff2') format('woff2'), url('./PlusJakartaSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bossa';
  src: url('./Bossa-Bold.woff2') format('woff2'), url('./Bossa-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
